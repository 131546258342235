<template>
  <div id="stepHeader" class="pb-32 md:pb-[6.5rem]">
    <client-only>
      <header
        class="fixed z-50 flex w-full flex-wrap items-center bg-white text-sm text-g7-blue shadow-lg md:h-[6.5rem] md:flex-nowrap"
      >
        <NuxtLink
          to="/"
          class="h-[4.271rem] w-2/3 bg-g7-blue pl-4 pt-4 md:ml-4 md:h-auto md:w-40 md:min-w-40 md:bg-white md:pb-4 md:pl-0"
        >
          <CloudinaryImg
            v-if="!isMobile"
            :public-id="useGo7seasLogo('go7seas-logo-blue')"
            :steps="[200, 400, 600]"
            class="m-auto my-2 hidden border-r-2 border-[#8996af] pr-4 md:block md:w-64"
          />
          <CloudinaryImg
            v-if="isMobile"
            :public-id="useGo7seasLogo()"
            :steps="[200, 400, 600]"
            class="h-8 md:hidden"
          />
        </NuxtLink>
        <template v-if="currentStep !== OrderStepEnum.CONFIRMATION">
          <G7Button
            v-if="currentStep === OrderStepEnum.AUDIT"
            class="ml-auto h-[4.271rem] w-1/3 text-sm md:order-last md:h-full md:w-40 md:max-w-40 md:p-4"
            color="orange"
            label="Bestätigen der Angaben"
            data-cy="submit"
            @click="scrollToIdPosition('confirmation', 250, 'smooth')"
          />
          <G7Button
            v-else
            class="ml-auto h-[4.271rem] w-1/3 px-4 text-sm md:order-last md:h-full md:w-40 md:max-w-40 md:p-4"
            :disabled="!canContinue || pending"
            color="orange"
            :label="booking.action_label"
            type="submit"
            :form="formId"
            data-cy="submit"
            @[!formId&&`click`]="useHandleSubmit"
          />
        </template>
        <HorizontalScroller
          class="h-full grow items-center"
          classes="items-center h-full"
          color="blue"
        >
          <template #scrollableContent>
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="my-2 flex h-full items-center md:my-0"
            >
              <BookingStep :step="step" :active="isActive(step.order_step)" />
              <div
                v-if="steps.length !== index + 1"
                class="flex h-full w-5 items-center"
                :class="bg(step, index)"
              >
                <IconsNext class="rotate-90" :class="fill(step, index)" />
              </div>
            </div>
          </template>
        </HorizontalScroller>
      </header>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import type { OrderStep } from "@/types/order";
import { OrderStepEnum } from "@/types/order";
import { MetaTagsSchema } from "~/schema/layout";

const { isMobile } = useIsMobile();

const booking = useBooking();
const steps = computed(() => {
  return booking.value.order_steps.map((s) => s).slice(0, -1);
});
const currentStep = computed(() => booking.value.current_step);
const selected = useBookingSelection();
const canContinue = computed(() => selected.value !== null);
const currentSlide: Ref<number | undefined> = ref();
const formId = getBookingFormId();

onMounted(() => {
  currentSlide.value = booking.value.order_steps.findIndex(
    (s) => currentStep.value === s.order_step
  );
  setMetaTagsForStep();
});

function isActive(orderStep: OrderStepEnum): boolean {
  const additions: Array<OrderStepEnum> = [OrderStepEnum.TRANSFERS];
  if (orderStep === OrderStepEnum.ADDITIONS) {
    return additions.includes(currentStep.value);
  }

  const personalData: Array<OrderStepEnum> = [
    OrderStepEnum.PERSONAL_DATA,
    OrderStepEnum.TRAVELERS,
  ];
  if (orderStep === "PERSONAL_DATA") {
    return personalData.includes(currentStep.value);
  }

  return currentStep.value === orderStep;
}
const pending: Ref<boolean> = useState("booking-loading");

watch(currentStep, () => {
  setMetaTagsForStep();
});

function fill(step: OrderStep, index: number) {
  return {
    "fill-teal": step.checked || isActive(step.order_step),
    "fill-white":
      step.checked &&
      booking.value[index + 1] &&
      isActive(booking[index + 1].order_step),
    "fill-silver": !step.checked && !isActive(step.order_step),
  };
}

function bg(step: OrderStep, index: number) {
  return {
    "bg-teal":
      step.checked &&
      booking[index + 1] &&
      isActive(booking[index + 1].order_step),
  };
}

async function setMetaTagsForStep() {
  const current = steps.value.find((item) => {
    if (isActive(item.order_step)) {
      return item;
    }
    return undefined;
  });

  if (current) {
    try {
      const metaTags = await MetaTagsSchema.validate(current.meta_tags);
      setHead(metaTags);
    } catch (error) {
      if (import.meta.client) {
        throw createError(String(error));
      }
    }
  }
}
</script>
