export const scrollToTop = () => {
  if (import.meta.client) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};

export const scrollToIdPosition = (
  id: string,
  offset = 0,
  behavior: "auto" | "smooth" = "auto"
) => {
  if (import.meta.client) {
    const element = document.getElementById(id);
    window.scrollTo({
      top: element.offsetTop - offset,
      behavior,
    });
  }
};
