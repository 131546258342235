import revive_payload_client_4sVQNw7RlN from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/go7seas/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/go7seas/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/go7seas/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/go7seas/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import components_m1q6StQDkO from "/builds/go7seas/website/plugins/components.ts";
import router_WMnTGednOQ from "/builds/go7seas/website/plugins/router.ts";
import sentry_client_shVUlIjFLk from "/builds/go7seas/website/plugins/sentry.client.ts";
import setTabToken_i8PttygeYw from "/builds/go7seas/website/plugins/setTabToken.ts";
import toastification_40V2sZJWam from "/builds/go7seas/website/plugins/toastification.js";
import vee_validate_KcKlKmvCrJ from "/builds/go7seas/website/plugins/vee-validate.ts";
import window_client_kn8L8U1g2M from "/builds/go7seas/website/plugins/window.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  components_m1q6StQDkO,
  router_WMnTGednOQ,
  sentry_client_shVUlIjFLk,
  setTabToken_i8PttygeYw,
  toastification_40V2sZJWam,
  vee_validate_KcKlKmvCrJ,
  window_client_kn8L8U1g2M
]