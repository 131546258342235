
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93p0g44J9TYKMeta } from "/builds/go7seas/website/pages/[...slug].vue?macro=true";
import { default as angebot_45anfrage02GjRGoRLWMeta } from "/builds/go7seas/website/pages/angebot-anfrage.vue?macro=true";
import { default as kabinen8dlmKUGDzHMeta } from "/builds/go7seas/website/pages/auswahl/kabinen.vue?macro=true";
import { default as kategorien8P7BCG7zHUMeta } from "/builds/go7seas/website/pages/auswahl/kategorien.vue?macro=true";
import { default as transfer8O5jqPmlopMeta } from "/builds/go7seas/website/pages/auswahl/transfer.vue?macro=true";
import { default as indexvQUhje6ZHMMeta } from "/builds/go7seas/website/pages/buchung/index.vue?macro=true";
import { default as pruefenb9BihnDmTPMeta } from "/builds/go7seas/website/pages/buchung/pruefen.vue?macro=true";
import { default as zahlungsartoD3x8KxsSMMeta } from "/builds/go7seas/website/pages/buchung/zahlungsart.vue?macro=true";
import { default as callbackCQCMq8HWKFMeta } from "/builds/go7seas/website/pages/callback.vue?macro=true";
import { default as einloggenLyDR1dizQ1Meta } from "/builds/go7seas/website/pages/einloggen.vue?macro=true";
import { default as _404HnysjBdU8fMeta } from "/builds/go7seas/website/pages/error/404.vue?macro=true";
import { default as _500YUTjzKUb0MMeta } from "/builds/go7seas/website/pages/error/500.vue?macro=true";
import { default as _503Ib6JegAtwCMeta } from "/builds/go7seas/website/pages/error/503.vue?macro=true";
import { default as indexh72C84hVOhMeta } from "/builds/go7seas/website/pages/index.vue?macro=true";
import { default as _91slug_93cosh3JqypvMeta } from "/builds/go7seas/website/pages/informationen/[slug].vue?macro=true";
import { default as indexAUQDdNUkFVMeta } from "/builds/go7seas/website/pages/informationen/index.vue?macro=true";
import { default as kontaktdro2lXCoohMeta } from "/builds/go7seas/website/pages/kontakt.vue?macro=true";
import { default as _91slug_93Sd3AymbdtfMeta } from "/builds/go7seas/website/pages/magazin/[slug].vue?macro=true";
import { default as indexjKuRTrdyqSMeta } from "/builds/go7seas/website/pages/magazin/index.vue?macro=true";
import { default as bewertungenLYDpe8PdzbMeta } from "/builds/go7seas/website/pages/mygo7seas/bewertungen.vue?macro=true";
import { default as profilrSj9PfOCAvMeta } from "/builds/go7seas/website/pages/mygo7seas/daten/bearbeiten/profil.vue?macro=true";
import { default as index4pDKUbIRXvMeta } from "/builds/go7seas/website/pages/mygo7seas/daten/index.vue?macro=true";
import { default as indexOCenDpgUvXMeta } from "/builds/go7seas/website/pages/mygo7seas/index.vue?macro=true";
import { default as kontaktx5d5Ii1J7bMeta } from "/builds/go7seas/website/pages/mygo7seas/kontakt.vue?macro=true";
import { default as merkzetteliFli0izrg6Meta } from "/builds/go7seas/website/pages/mygo7seas/merkzettel.vue?macro=true";
import { default as _91_46_46_46slug_93ZKnkMmD7JFMeta } from "/builds/go7seas/website/pages/newsletter/abmelden/[...slug].vue?macro=true";
import { default as anmeldendgWFHumupDMeta } from "/builds/go7seas/website/pages/newsletter/anmelden.vue?macro=true";
import { default as vergessenWKUl8Wj6b5Meta } from "/builds/go7seas/website/pages/passwort/vergessen.vue?macro=true";
import { default as reedereienkIanDOTjijMeta } from "/builds/go7seas/website/pages/reedereien.vue?macro=true";
import { default as registrierungu7PnQKAt8kMeta } from "/builds/go7seas/website/pages/registrierung.vue?macro=true";
import { default as agbX9HxUZNnuQMeta } from "/builds/go7seas/website/pages/service/agb.vue?macro=true";
import { default as datenschutzLEXeAqkDpHMeta } from "/builds/go7seas/website/pages/service/datenschutz.vue?macro=true";
import { default as gutscheinefkUGKPqEoMeta } from "/builds/go7seas/website/pages/service/gutschein.vue?macro=true";
import { default as gutscheinkaufensojxRoiASgMeta } from "/builds/go7seas/website/pages/service/gutscheinkaufen.vue?macro=true";
import { default as impressumnk12M57fulMeta } from "/builds/go7seas/website/pages/service/impressum.vue?macro=true";
import { default as jobs56GljzpYBIMeta } from "/builds/go7seas/website/pages/service/jobs.vue?macro=true";
import { default as _91_46_46_46suche_93MAQvOM2tBXMeta } from "/builds/go7seas/website/pages/suche/[...suche].vue?macro=true";
import { default as zielgebiete0upM0zUuG8Meta } from "/builds/go7seas/website/pages/zielgebiete.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/go7seas/website/pages/[...slug].vue")
  },
  {
    name: "angebot-anfrage",
    path: "/angebot-anfrage",
    meta: angebot_45anfrage02GjRGoRLWMeta || {},
    component: () => import("/builds/go7seas/website/pages/angebot-anfrage.vue")
  },
  {
    name: "auswahl-kabinen",
    path: "/auswahl/kabinen",
    meta: kabinen8dlmKUGDzHMeta || {},
    component: () => import("/builds/go7seas/website/pages/auswahl/kabinen.vue")
  },
  {
    name: "auswahl-kategorien",
    path: "/auswahl/kategorien",
    meta: kategorien8P7BCG7zHUMeta || {},
    component: () => import("/builds/go7seas/website/pages/auswahl/kategorien.vue")
  },
  {
    name: "auswahl-transfer",
    path: "/auswahl/transfer",
    meta: transfer8O5jqPmlopMeta || {},
    component: () => import("/builds/go7seas/website/pages/auswahl/transfer.vue")
  },
  {
    name: "buchung",
    path: "/buchung",
    meta: indexvQUhje6ZHMMeta || {},
    component: () => import("/builds/go7seas/website/pages/buchung/index.vue")
  },
  {
    name: "buchung-pruefen",
    path: "/buchung/pruefen",
    meta: pruefenb9BihnDmTPMeta || {},
    component: () => import("/builds/go7seas/website/pages/buchung/pruefen.vue")
  },
  {
    name: "buchung-zahlungsart",
    path: "/buchung/zahlungsart",
    meta: zahlungsartoD3x8KxsSMMeta || {},
    component: () => import("/builds/go7seas/website/pages/buchung/zahlungsart.vue")
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/builds/go7seas/website/pages/callback.vue")
  },
  {
    name: "einloggen",
    path: "/einloggen",
    component: () => import("/builds/go7seas/website/pages/einloggen.vue")
  },
  {
    name: "error-404",
    path: "/error/404",
    meta: _404HnysjBdU8fMeta || {},
    component: () => import("/builds/go7seas/website/pages/error/404.vue")
  },
  {
    name: "error-500",
    path: "/error/500",
    meta: _500YUTjzKUb0MMeta || {},
    component: () => import("/builds/go7seas/website/pages/error/500.vue")
  },
  {
    name: "error-503",
    path: "/error/503",
    meta: _503Ib6JegAtwCMeta || {},
    component: () => import("/builds/go7seas/website/pages/error/503.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/go7seas/website/pages/index.vue")
  },
  {
    name: "informationen-slug",
    path: "/informationen/:slug()",
    component: () => import("/builds/go7seas/website/pages/informationen/[slug].vue")
  },
  {
    name: "informationen",
    path: "/informationen",
    component: () => import("/builds/go7seas/website/pages/informationen/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/builds/go7seas/website/pages/kontakt.vue")
  },
  {
    name: "magazin-slug",
    path: "/magazin/:slug()",
    component: () => import("/builds/go7seas/website/pages/magazin/[slug].vue")
  },
  {
    name: "magazin",
    path: "/magazin",
    component: () => import("/builds/go7seas/website/pages/magazin/index.vue")
  },
  {
    name: "mygo7seas-bewertungen",
    path: "/mygo7seas/bewertungen",
    meta: bewertungenLYDpe8PdzbMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/bewertungen.vue")
  },
  {
    name: "mygo7seas-daten-bearbeiten-profil",
    path: "/mygo7seas/daten/bearbeiten/profil",
    meta: profilrSj9PfOCAvMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/daten/bearbeiten/profil.vue")
  },
  {
    name: "mygo7seas-daten",
    path: "/mygo7seas/daten",
    meta: index4pDKUbIRXvMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/daten/index.vue")
  },
  {
    name: "mygo7seas",
    path: "/mygo7seas",
    meta: indexOCenDpgUvXMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/index.vue")
  },
  {
    name: "mygo7seas-kontakt",
    path: "/mygo7seas/kontakt",
    meta: kontaktx5d5Ii1J7bMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/kontakt.vue")
  },
  {
    name: "mygo7seas-merkzettel",
    path: "/mygo7seas/merkzettel",
    meta: merkzetteliFli0izrg6Meta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/merkzettel.vue")
  },
  {
    name: "newsletter-abmelden-slug",
    path: "/newsletter/abmelden/:slug(.*)*",
    component: () => import("/builds/go7seas/website/pages/newsletter/abmelden/[...slug].vue")
  },
  {
    name: "newsletter-anmelden",
    path: "/newsletter/anmelden",
    component: () => import("/builds/go7seas/website/pages/newsletter/anmelden.vue")
  },
  {
    name: "passwort-vergessen",
    path: "/passwort/vergessen",
    component: () => import("/builds/go7seas/website/pages/passwort/vergessen.vue")
  },
  {
    name: "reedereien",
    path: "/reedereien",
    component: () => import("/builds/go7seas/website/pages/reedereien.vue")
  },
  {
    name: "registrierung",
    path: "/registrierung",
    component: () => import("/builds/go7seas/website/pages/registrierung.vue")
  },
  {
    name: "service-agb",
    path: "/service/agb",
    component: () => import("/builds/go7seas/website/pages/service/agb.vue")
  },
  {
    name: "service-datenschutz",
    path: "/service/datenschutz",
    component: () => import("/builds/go7seas/website/pages/service/datenschutz.vue")
  },
  {
    name: "service-gutschein",
    path: "/service/gutschein",
    component: () => import("/builds/go7seas/website/pages/service/gutschein.vue")
  },
  {
    name: "service-gutscheinkaufen",
    path: "/service/gutscheinkaufen",
    component: () => import("/builds/go7seas/website/pages/service/gutscheinkaufen.vue")
  },
  {
    name: "service-impressum",
    path: "/service/impressum",
    component: () => import("/builds/go7seas/website/pages/service/impressum.vue")
  },
  {
    name: "service-jobs",
    path: "/service/jobs",
    component: () => import("/builds/go7seas/website/pages/service/jobs.vue")
  },
  {
    name: "suche-suche",
    path: "/suche/:suche(.*)*",
    component: () => import("/builds/go7seas/website/pages/suche/[...suche].vue")
  },
  {
    name: "zielgebiete",
    path: "/zielgebiete",
    component: () => import("/builds/go7seas/website/pages/zielgebiete.vue")
  }
]