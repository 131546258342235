<template>
  <div
    class="flex cursor-pointer items-center justify-center rounded-full bg-teal"
    @click="handleClick"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { Share } from "~/types/request";

const props = defineProps<Share>();

const emit = defineEmits(["toggleModal"]);

async function handleClick() {
  if (navigator.share) {
    try {
      await navigator.share({
        title: props.title,
        text: props.title,
        url: props.url,
      });
    } catch (error) {
      if (!(error instanceof Error)) {
        emit("toggleModal");
        return;
      }
      if (error.name !== "AbortError") {
        emit("toggleModal");
      }
    }
  } else {
    emit("toggleModal");
  }
}
</script>
