import type { Ref } from "vue";
import { DefaultLayoutSchema, BookingLayoutSchema } from "~/schema/layout";
import type { DefaultLayout, BookingLayout } from "~/schema/layout";

export const useDefaultLayout = (): Ref<DefaultLayout> =>
  useState<DefaultLayout>("default-layout-data", () => ({
    header: {
      hotline: {
        domestic: "Hotline: 040 377072-500",
        abroad_title: "Mobile & Ausland: ",
        abroad: "+49 (0)40 377072-500",
      },
      opening_hours: ["Mo-Fr: 9:00 - 18:00 Uhr", "Sa & So: 10:00 - 18:00 Uhr"],
      action_button: {
        label: "My Go7Seas",
        context: "",
      },
    },
    footer: {
      menus: [],
      label_button: "Top",
      label_name: "Reisebüro Trans Global Tours GmbH & Co. KG",
      label_social: "Finden Sie uns auch auf",
      social_icons: [
        {
          icon: "fab fa-instagram fa-stack-1x icon-dark",
          link: "https://www.instagram.com/go7seas/",
        },
        {
          icon: "fab fa-facebook-f fa-stack-1x icon-dark",
          link: "https://www.facebook.com/go7seas/",
        },
        {
          icon: "fab fa-twitter fa-stack-1x icon-dark",
          link: "https://twitter.com/go7seas",
        },
      ],
    },
    main_menu: {
      items: [
        {
          context: "/",
          label: "start",
          active: false,
        },
        {
          context: "/reedereien",
          label: "reedereien",
          active: false,
        },
        {
          context: "/zielgebiete",
          label: "Kreuzfahrtgebiete",
          active: false,
        },
        {
          context: "/magazin",
          label: "Magazin",
          active: false,
        },
        {
          context: "/informationen",
          label: "Fragen & Antworten",
          active: false,
        },
      ],
    },
  }));

export const updateDefaultLayout = async (
  data: DefaultLayout | null
): Promise<void> => {
  try {
    const validData = await DefaultLayoutSchema.validate(data);

    if (validData.meta_tags) {
      setHead(validData.meta_tags);
    }

    if (import.meta.client) {
      const layout = useState<DefaultLayout>("default-layout-data");
      layout.value.header = validData.header;
      layout.value.footer = validData.footer;
      layout.value.main_menu = validData.main_menu;
    }
  } catch (error) {
    if (import.meta.client) {
      throw createError(String(error));
    }
  }
};

export const updateBookingLayout = async (
  data: BookingLayout
): Promise<void> => {
  try {
    const validData = await BookingLayoutSchema.validate(data);

    const layout = useState<DefaultLayout>("default-layout-data");
    layout.value.footer = validData.footer;
  } catch (error) {
    if (import.meta.client) {
      throw createError(String(error));
    }
  }
};
