<template>
  <div
    class="flex size-full flex-col justify-between text-g7-blue md:border-2 md:border-solid md:border-inherit"
  >
    <NuxtLink :to="item.image.context" :target="item.image.target">
      <div
        class="aspect-h-[18] aspect-w-[29] relative md:aspect-h-9 md:aspect-w-16"
      >
        <div style="background-color: #a4cadd">
          <CloudinaryImg
            :public-id="item.image.public_id"
            :steps="[200, 400, 600]"
            class="size-full object-cover"
          />
          <div
            class="absolute top-0 z-10 mx-4 my-2 size-min whitespace-nowrap rounded-md bg-g7-blue px-1 text-white md:bottom-0 md:top-auto"
          >
            <span class="text-xl" v-html="`${duration[0]} `" />
            <span v-html="duration[1]" />
          </div>
          <CloudinaryImg
            :public-id="item.cruise_line_logo.public_id"
            :steps="[200, 400]"
            class="absolute right-0 top-0 mx-4 my-2 h-5 cursor-pointer md:bottom-0 md:top-auto md:h-8"
          />
          <div
            class="absolute bottom-0 w-full bg-white/80 p-2 text-left md:hidden"
          >
            <Headline size="3XL" class="mx-2 normal-case" :title="item.title" />
          </div>
        </div>
      </div>
    </NuxtLink>
    <div class="mx-2 mt-1 hidden grow text-left md:mx-4 md:mt-2 md:block">
      <Headline class="normal-case" :title="item.title" />
    </div>
    <div class="mx-4 mt-2 text-left">
      <div class="flex w-full justify-between">
        <Headline
          :h="3"
          size="3XL"
          class="font-black normal-case md:hidden"
          :title="item.ship_name"
        />
        <Headline
          :h="3"
          size="XL"
          class="hidden font-black normal-case md:block"
          :title="item.ship_name"
        />
        <div class="flex items-center md:hidden">
          <div class="mr-1 size-8 md:size-10 3xl:size-8">
            <IconsShip
              class="size-8 rounded-full bg-teal fill-white p-1 md:size-10 md:p-2 3xl:size-8"
            />
          </div>
          <div
            v-if="item.includes_hotel"
            class="mr-1 size-8 md:size-10 3xl:size-8"
          >
            <IconsBed
              class="size-8 rounded-full bg-teal fill-white p-1 md:size-10 md:p-2 3xl:size-8"
            />
          </div>
          <div
            v-if="item.includes_flight"
            class="mr-1 size-8 md:size-10 3xl:size-8"
          >
            <IconsPlane
              class="size-8 rounded-full bg-teal fill-white p-1 md:size-10 md:p-2 3xl:size-8"
            />
          </div>
        </div>
      </div>
      <span
        class="text-xl font-medium md:text-base"
        v-html="item.region_name"
      />
    </div>
    <div class="mx-2 flex items-center px-1 md:mx-0">
      <IconsCalendar class="size-8 fill-g7-blue p-1 md:size-11 md:p-3" />
      <span class="text-base font-bold decoration-transparent">
        {{ item.date_line }}
      </span>
    </div>

    <hr class="mx-2 md:mx-4" />

    <div class="flex flex-row justify-between p-2 md:p-4">
      <div class="hidden h-full grow items-center md:flex">
        <div class="mr-1 size-6 md:size-10 3xl:size-8">
          <IconsShip
            class="size-6 rounded-full bg-teal fill-white p-1 md:size-10 md:p-2 3xl:size-8"
          />
        </div>
        <div
          v-if="item.includes_hotel"
          class="mr-1 size-6 md:size-10 3xl:size-8"
        >
          <IconsBed
            class="size-6 rounded-full bg-teal fill-white p-1 md:size-10 md:p-2 3xl:size-8"
          />
        </div>
        <div
          v-if="item.includes_flight"
          class="mr-1 size-6 md:size-10 3xl:size-8"
        >
          <IconsPlane
            class="size-6 rounded-full bg-teal fill-white p-1 md:size-10 md:p-2 3xl:size-8"
          />
        </div>
      </div>
      <div
        class="mx-2 flex w-full justify-between text-left md:mx-0 md:flex-col md:items-end md:justify-end md:text-right 3xl:flex-row"
      >
        <div class="flex flex-col">
          <span
            class="hidden text-base decoration-transparent md:block 3xl:text-sm"
          >
            {{ item.price.context }} {{ item.price.prefix }}
          </span>
          <span class="text-xl decoration-transparent md:hidden">
            {{ item.price.context }}
          </span>

          <div>
            <span class="mr-1 text-xl decoration-transparent md:hidden">
              {{ item.price.prefix }}
            </span>
            <span
              class="ml-1 text-3xl font-bold md:text-2xl md:font-normal"
              v-html="item.price.formatted"
            />
          </div>
        </div>
        <G7Button
          class="h-3/4 self-end px-6 md:hidden"
          :label="item.action.label"
          :to="item.action.context"
          :target="item.action.target"
          color="orange"
        />
      </div>
    </div>

    <G7Button
      class="hidden py-1 md:block"
      :label="item.action.label"
      :to="item.action.context"
      :target="item.action.target"
      data-cy="start"
    />
  </div>
</template>

<script lang="ts" setup>
import type { CruiseTopOffer } from "~~/types/cruise";

const props = defineProps<{
  item: CruiseTopOffer;
}>();
const duration = props.item.duration.split(" ");
</script>
