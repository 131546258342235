<template>
  <div class="flex w-full flex-col border-2">
    <component
      :is="component"
      :to="to"
      :target="target"
      class="aspect-h-9 aspect-w-16 relative"
    >
      <div style="background-color: #a4cadd" class="size-full">
        <CloudinaryImg
          :public-id="item.image.public_id"
          :steps="[200, 400, 600]"
          class="size-full object-cover"
        />
        <div class="absolute inset-x-0 bottom-0 z-50 w-full flex-col">
          <div class="flex w-full items-center justify-center bg-g7-blue/60">
            <Headline
              class="m-2 text-center lg:m-4 lg:font-normal"
              size="3XL"
              color="white"
              :title="item.title"
            />
          </div>
        </div>
      </div>
    </component>
    <G7Button
      v-if="item.action_button"
      class="py-1"
      :label="item.action_button.label"
      :to="item.action_button.context"
      :target="item.action_button.target"
    />
  </div>
</template>

<script lang="ts" setup>
import type { CruiseTeaser } from "~~/types/cruise";

const props = withDefaults(
  defineProps<{
    item: CruiseTeaser;
    imgClickable?: boolean;
  }>(),
  {
    imgClickable: false,
  }
);

const component = computed(() => {
  if (props.item.image.context || props.imgClickable) {
    return resolveComponent("NuxtLink");
  }
  return "div";
});

const to = computed(() => {
  return props.item.image.context ?? props.item.action_button.context;
});

const target = computed(() => {
  return props.item.image.target ?? props.item.action_button.target;
});
</script>
