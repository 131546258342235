<template>
  <component :is="page" v-if="response" :links="response._links"></component>
</template>

<script lang="ts" setup>
import { PageTypeSchema } from "~/schema/response";
import type { PageResponse } from "~/types/response.ts";

const params = useRoute().params;

const mapping = {
  reederei: resolveComponent("ViewReederei"),
  hafen: resolveComponent("ViewHafen"),
  schiff: resolveComponent("ViewSchiff"),
  zielgebiet: resolveComponent("ViewZielgebiet"),
  reise: resolveComponent("ViewReise"),
  "/": resolveComponent("View"),
};

const route = Array.isArray(params.slug) ? params.slug.join("/") : params.slug;
const page = shallowRef(null);

if (route.startsWith("media")) {
  window.location.href = route;
  window.location.reload();
}

const { data: response } = await useGo7seasApi<PageResponse>(
  apiUrl(`/catalog/page/${route}`)
);

try {
  await PageTypeSchema.validate(response.value);
} catch (error) {
  throw createError({
    statusCode: 500,
    statusMessage:
      "Invalid API response: 'page_type' property is required and must be one of the following values: 'reederei', 'hafen', 'schiff', 'zielgebiet', 'reise', '/'. Ensure the API provides a valid 'page_type' field.",
    fatal: true,
  });
}

page.value = mapping[response.value.page_type];

if (!response.value._links) {
  throw createError({
    statusCode: 500,
    statusMessage:
      "Invalid API response: '_links' property is required but missing. Ensure the API provides all necessary fields.",
    fatal: true,
  });
}
</script>
