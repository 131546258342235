import type { CabinLaf, CabinType, Price } from "./order";
import type { Links } from "./layout";
import type { CloudinaryImage } from "@/types/image";
import type {
  LabelValue,
  LabelContext,
  SelectBox,
  TextField,
  Checkbox,
  ActionButton,
  ActionLink,
  CommunicationField,
  CaptchaFormFields,
} from "@/types/form";

export enum OfferTypeEnum {
  ALL_INCLUSIVE = "ALL_INCLUSIVE",
  ALL_INCLUSIVE_PACKAGED = "ALL_INCLUSIVE_PACKAGED",
  CRUISE_ONLY = "CRUISE_ONLY",
  OWN_PACKAGED = "OWN_PACKAGED",
  PACKAGED = "PACKAGED",
}

export enum CruiseLafEnum {
  BALCONY,
  INTERIOR,
  OCEANVIEW,
  SUITE,
}

export interface CruisePriceOptions {
  adults_select: SelectBox;
  first_child_age_select?: SelectBox;
  second_child_age_select?: SelectBox;
  cabin_types_select?: SelectBox;
  action_button: ActionButton;
  loyalty_program_member_number_field?: TextField;
  loyalty_program_member_checkbox?: Checkbox;
}

export interface CruiseLaf {
  amount: Price;
  cabin_type: CruiseLafEnum;
  links?: Links;
}

export interface CruiseItineray {
  arrival_time: string;
  date: string;
  departure_time: string;
  harbour_name: string;
}

export interface CruiseDetail {
  title: string;
  subtitle: string;
  banner: CloudinaryImage;
  banner_mobile: CloudinaryImage;
  cruise_line_logo: CloudinaryImage;
  ship_name: string;
  motto: string;
  includes_hotel: boolean;
  includes_flight: boolean;
  offer_type: OfferTypeEnum;
  itinerary: CruiseItineray[];
  selling_points: Array<string>;
  cruise_laf?: CruiseLaf[];
  next_cruise?: LabelContext;
  previous_cruise?: LabelContext;
  tabs: Array<LabelValue>;
}

export interface CruiseServiceFeatured {
  title: string;
  subtitle: string;
  image: CloudinaryImage;
  description: string;
}

export interface CruiseTeaser {
  image: CloudinaryImage;
  title: string;
  text: string;
  subtitle?: string;
  action_button?: ActionButton;
}

export interface CruiseExpert {
  description?: string;
  e_mail: string;
  e_mail_label: string;
  image?: CloudinaryImage;
  motto?: string;
  name: string;
  telephone_label: string;
  telephone_number: string;
}
export interface CruiseListOfferType {
  action_button: ActionButton;
  admonitions: {
    admonitions: Array<string>;
    show_icon: boolean;
  };
  cabin_type_lafs: Array<CabinType>;
  check_box_field?: Checkbox;
  cruise_laf: CabinLaf;
  cruise_line_logo: CloudinaryImage;
  cruise_line_name: LabelValue;
  cruise_region_name: LabelValue;
  display_id: LabelValue;
  duration: LabelValue;
  itinerary: LabelValue;
  image: CloudinaryImage;
  includes_flight: boolean;
  includes_hotel: boolean;
  offer_type: string;
  period: LabelValue;
  ship_name: LabelValue;
  title: LabelContext;
}

interface CalendarRows {
  cruise_region_name: string;
  availability_slugs: Array<string>;
}

export interface CruiseCalendar {
  title: string;
  column_title_regions: string;
  column_titles_dates: Array<string>;
  load_more_label: string;
  calendar_rows?: Array<CalendarRows>;
}

export interface CruiseTopOffer {
  action: ActionLink;
  date_line: string;
  image: CloudinaryImage;
  includes_flight: boolean;
  includes_hotel: boolean;
  offer_type: OfferTypeEnum;
  price: Price;
  region_name: string;
  ship_name: string;
  duration: string;
  cruise_line_logo: CloudinaryImage;
  title: string;
}

export interface CruiseStation {
  title: string;
  date: string;
  arrival_time: string;
  departure_time: string;
  description: string;
  image: CloudinaryImage;
  show_more_button?: ActionButton;
  show_less_button?: ActionButton;
  mandatory_text: boolean;
}

export interface CruisePromotion {
  action_link?: LabelContext;
  background_color?: string;
  border_color?: string;
  description?: string;
  guideline?: string;
  image?: CloudinaryImage;
  links?: Links;
  show_less_button?: LabelContext;
  show_more_button?: LabelContext;
  smallprint?: string;
  subtitle?: string;
  title: string;
}

export interface SimilarCruise {
  action_button: ActionButton;
  arrival_date: string;
  departure_date: string;
  lafs: Array<CruiseLaf>;
  links?: Links;
}

export interface RecommendationBlock {
  title: string;
  privacy_notices: string;
  name_field: TextField;
  message_field: TextField;
  friends_name_field: TextField;
  friends_e_mail_field: CommunicationField;
  e_mail_field: CommunicationField;
  captcha: CaptchaFormFields;
  action_button: ActionButton;
}
