<template>
  <VueCarousel
    v-if="response && response.logos.length"
    class="my-8 w-full border-y-2 py-8 shadow-lg"
  >
    <Carousel :settings="settings" :breakpoints="breakpoints" class="mx-8">
      <Slide v-for="(logo, index) in response.logos" :key="index">
        <NuxtLink :to="logo.context" :target="logo.target">
          <IconsCruiselineLogo :image="logo" />
        </NuxtLink>
      </Slide>
      <template #addons>
        <navigation>
          <template #next>
            <span><IconsUp class="size-14 rotate-90 fill-g7-blue" /></span>
          </template>
          <template #prev>
            <span><IconsUp class="size-14 -rotate-90 fill-g7-blue" /></span>
          </template>
        </navigation>
      </template>
    </Carousel>
  </VueCarousel>
</template>

<script lang="ts" setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import type { CruiselineLogosResponse } from "~~/types/response";

const props = defineProps({
  url: {
    type: String,
    default: "",
  },
});

const settings = ref({});
const breakpoints = ref({});

const targetUrl =
  props.url === ""
    ? apiUrl("/catalog/homepages/go7seas/cruiseline-logos")
    : props.url;

const { data: response } = await useGo7seasApi<CruiselineLogosResponse>(
  targetUrl,
  {
    key: targetUrl,
  }
);

if (response && response.value?.logos.length) {
  settings.value = {
    itemsToShow: 1,
    snapAlign: "center",
    wrapAround: response.value.logos.length > 1,
  };

  breakpoints.value = {
    700: {
      itemsToShow: 2,
      snapAlign: response.value.logos.length <= 2 ? "center" : "start",
      wrapAround: response.value.logos.length > 2,
    },
    1024: {
      itemsToShow: 5,
      snapAlign: response.value.logos.length <= 5 ? "center" : "start",
      wrapAround: response.value.logos.length > 5,
    },
  };
}
</script>
