<template>
  <VueCarousel v-if="diaShow.has().value">
    <Carousel :settings="settings" class="overflow-hidden">
      <Slide
        v-for="(dia, index) in diaShow.get()"
        :key="index"
        class="flex w-full items-center justify-center"
      >
        <HomeBookingTeaser
          v-if="isMobile !== null && show"
          :is-mobile="isMobile"
          :teaser="dia"
        />
      </Slide>
      <template #addons>
        <navigation v-if="settings.wrapAround">
          <template #next>
            <span class="hidden lg:inline">
              <IconsUp class="mr-[6.25rem] size-14 rotate-90 fill-white" />
            </span>
          </template>
          <template #prev>
            <span class="hidden lg:inline">
              <IconsUp class="ml-[6.25rem] size-14 -rotate-90 fill-white" />
            </span>
          </template>
        </navigation>
      </template>
    </Carousel>
  </VueCarousel>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

const diaShow = useDiaShow();

const settings = {
  autoplay: 5000,
  wrapAround: diaShow.get().length > 1,
};

const isMobile: Ref<null | boolean> = ref(null);
const show: Ref<boolean> = ref(true);
function onResize() {
  isMobile.value = window.innerWidth < 768;
}

onMounted(() => {
  if (import.meta.client) {
    window.onresize = onResize;
    onResize();
  }
});

watch(isMobile, async () => {
  show.value = false;
  await nextTick();
  show.value = true;
});

onBeforeUnmount(() => {
  window.onresize = null;
});
</script>
