<template>
  <component
    :is="component"
    :class="classes"
    :type="type"
    class="relative cursor-pointer text-xs font-black text-white transition-all disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 sm:text-base sm:font-normal md:px-3 md:py-1 md:text-lg"
    :disabled="disabled"
    :data-cy="dataCy"
    :to="to"
    :target="target"
  >
    <div v-if="pending" class="flex h-full items-center justify-center">
      <LoadingSpinner size="xs" color="white" />
    </div>

    <div
      :class="{ invisible: pending }"
      class="flex h-full items-center justify-center"
      v-html="label"
    />
  </component>
</template>

<script setup lang="ts">
import type { ButtonColors, Target } from "~~/types/form";

interface Props {
  label: string;
  to?: string;
  target?: Target;
  color?: ButtonColors;
  pending?: boolean;
  disabled?: boolean;
  type?: "submit" | "button";
  dataCy?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  to: "",
  target: undefined,
  color: "blue",
  pending: false,
  disabled: false,
  type: "button",
  dataCy: "",
});

const colorClasses = {
  blue: "bg-g7-blue hover:bg-[#071c43] active:bg-[#1d52b2]",
  orange: "bg-golden-bell hover:bg-orange-600 active:bg-orange-700",
  teal: "bg-teal hover:bg-[#3c94be] active:bg-[#3584a9]",
};

const classes = computed(() => {
  return colorClasses[props.color];
});

const component = computed(() => {
  if (props.to) {
    return resolveComponent("NuxtLink");
  }
  return "button";
});
</script>
