<template>
  <div class="flex flex-col">
    <template v-for="(item, index) in navItems" :key="index">
      <div
        v-if="item.label === 'Ausloggen'"
        class="cursor-pointer"
        :class="classes(item.context)"
        @click="useAuth().logout()"
      >
        <span v-html="item.label" />
      </div>
      <NuxtLink
        v-else
        :to="item.context"
        :target="item.target"
        :class="classes(item.context)"
      >
        <span v-html="item.label" />
      </NuxtLink>
    </template>
  </div>
</template>
<script lang="ts" setup>
import type { LabelContext } from "~~/types/form";

const props = defineProps<{
  items: LabelContext[];
}>();

const navItems = computed(() => {
  if (useRoute().path.includes("/mygo7seas")) {
    return [
      ...props.items,
      { label: "Suche", context: "/suche" },
      { label: "Ausloggen", context: "" },
    ];
  } else if (useRoute().path === "/einloggen") {
    return [...props.items, { label: "Suche", context: "/suche" }];
  } else {
    return [
      ...props.items,
      { label: "Detailsuche", context: "/suche" },
      { label: "Newsletter abonnieren", context: "/newsletter/anmelden" },
      { label: "My Go7Seas", context: "/mygo7seas" },
    ];
  }
});

const classes = (to) => {
  let style =
    "p-2 border-t-2 bg-g7-blue text-center text-sm sm:text-xl font-black text-white uppercase w-screen ";
  if (useRoute().path === to) {
    style = style + "hidden";
  }
  return style;
};
</script>
