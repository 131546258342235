export function useIsMobile() {
  const isMobile: Ref<null | boolean> = ref(null);

  function onResize() {
    isMobile.value = window.innerWidth < 768;
  }

  onMounted(() => {
    if (import.meta.client) {
      window.onresize = onResize;
      onResize();
    }
  });

  onBeforeUnmount(() => {
    window.onresize = null;
  });

  return { isMobile };
}
