<template>
  <div class="relative lg:flex">
    <div
      v-if="isMobile"
      class="aspect-h-1 aspect-w-1 mt-2 block w-full bg-cover bg-center text-white md:hidden"
      style="background-color: #a4cadd"
    >
      <CloudinaryImg
        :public-id="item.banner_mobile.public_id"
        :steps="640"
        class="md:hidden"
      />
      <div class="absolute flex w-full flex-col">
        <div class="flex flex-col px-4 pt-2">
          <Headline
            class="text-shadow-headline"
            color="white"
            size="3XL"
            :title="item.title"
          />
          <Headline color="white" size="2XL" :title="item.subtitle" />
        </div>
        <div class="flex h-full items-end justify-end">
          <CloudinaryImg
            :public-id="item.cruise_line_logo.public_id"
            :steps="[640, 1024]"
            class="mx-4 mb-2 h-8 sm:h-12"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!isMobile"
      class="aspect-h-[760] aspect-w-[1920] mt-2 hidden w-full bg-cover bg-center text-white md:block lg:mt-0"
      style="background-color: #a4cadd"
    >
      <CloudinaryImg
        :public-id="item.banner.public_id"
        :steps="[768, 1024, 1280, 1536]"
        class="hidden md:block"
      />
      <div class="absolute flex w-full flex-col lg:justify-between xl:flex-row">
        <div class="flex flex-col px-4 pt-2 lg:p-8">
          <Headline
            class="text-shadow-headline"
            color="white"
            size="3XL"
            :title="item.title"
          />
          <Headline color="white" size="2XL" :title="item.subtitle" />
        </div>
        <div
          class="flex size-full items-end justify-end lg:items-end lg:justify-start xl:items-start xl:justify-end"
        >
          <CloudinaryImg
            :public-id="item.cruise_line_logo.public_id"
            :steps="[640, 1024]"
            class="mx-4 mb-2 h-8 sm:h-12 lg:h-16 xl:m-8"
          />
        </div>
      </div>
    </div>

    <div class="absolute inset-x-0 bottom-0 hidden xl:block">
      <slot />
    </div>

    <div
      class="static bottom-0 right-0 flex items-center justify-center pt-8 text-white lg:absolute lg:items-end lg:justify-end lg:pt-0 xl:bottom-[5.5rem]"
    >
      <div class="border bg-g7-blue lg:border-0 lg:bg-g7-blue/50">
        <div class="flex">
          <div
            class="ml-2 flex w-full flex-col justify-center overflow-x-hidden"
          >
            <Headline
              class="whitespace-nowrap font-black normal-case italic lg:not-italic"
              color="white"
              :title="item.ship_name"
            />
            <Headline
              class="truncate normal-case"
              color="white"
              :title="item.motto"
            />
          </div>
          <div class="flex items-center gap-2 px-2 sm:gap-3 sm:px-4 lg:px-12">
            <IconsShip
              class="size-6 rounded-full bg-teal fill-white p-1 sm:size-8 lg:size-10 lg:p-2"
            />
            <IconsBed
              v-if="item.includes_hotel"
              class="size-6 rounded-full bg-teal fill-white p-1 sm:size-8 lg:size-10 lg:p-2"
            />
            <IconsPlane
              v-if="item.includes_flight"
              class="size-6 rounded-full bg-teal fill-white p-1 sm:size-8 lg:size-10 lg:p-2"
            />
          </div>
        </div>
        <hr class="border border-white lg:border-teal" />

        <div class="flex flex-col p-2 px-4 lg:p-8">
          <ul>
            <li
              v-for="(point, index) in item.selling_points"
              :key="index"
              class="my-1 flex"
            >
              <div class="flex items-center text-xs sm:text-sm lg:text-base">
                <div class="size-1 rounded-full bg-white lg:hidden" />
                <IconsUp class="hidden size-6 rotate-90 fill-white lg:block" />
                <span class="ml-2"> {{ point }}</span>
              </div>
            </li>
          </ul>
          <div class="flex h-1/2 items-end justify-between">
            <div class="mr-8">
              <div class="flex w-max items-center">
                <div
                  class="flex size-5 cursor-pointer items-center justify-center rounded-full border border-white sm:size-8 lg:size-10"
                  @click="toggleModal"
                >
                  <IconsMapLocation
                    class="size-3 fill-white sm:size-4 lg:size-6"
                  />
                </div>
                <span
                  class="ml-1 cursor-pointer text-xs uppercase underline sm:text-sm lg:ml-2 lg:text-base"
                  @click="showItinerary"
                >
                  Details Anzeigen
                </span>
              </div>
            </div>
            <div class="flex w-full flex-col items-end">
              <div class="py-2 text-xs sm:text-sm lg:pb-0 lg:pt-4 lg:text-base">
                <div class="flex items-end justify-end">
                  <span
                    class="leading-5 sm:leading-6"
                    v-html="laf.amount.prefix"
                  />
                  <Headline
                    class="ml-2 cursor-pointer"
                    size="3XL"
                    color="white"
                    :title="laf.amount.formatted"
                    @click="emit('init')"
                  />
                </div>
                <span class="flex justify-end" v-html="laf.amount.context" />
              </div>

              <G7Button
                class="h-field px-2 text-xs sm:text-sm md:text-base lg:hidden"
                color="orange"
                label="Angebot anzeigen"
                data-cy="offer"
                @click="emit('init')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CruiseDetail, CruiseLaf } from "~~/types/cruise";

const emit = defineEmits(["init", "toggleModal"]);
defineProps<{
  item: CruiseDetail;
  laf: CruiseLaf;
}>();

const { isMobile } = useIsMobile();
const offset = computed(() => {
  if (import.meta.client) {
    return isMobile.value ? 0 : window.innerHeight / 2;
  }
});

function showItinerary() {
  if (useState("cruiseTab").value === "ITINERARY") {
    scrollToIdPosition("cruisetabs", offset.value, "smooth");
  } else {
    useState("cruiseTab").value = "ITINERARY";
    useLoading(true);
  }
}

function toggleModal() {
  isMobile.value ? showItinerary() : emit("toggleModal");
}

watch(useLoading(), () => {
  if (useLoading().value === false)
    scrollToIdPosition("cruisetabs", offset.value, "smooth");
});
</script>
