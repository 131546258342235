<template>
  <div>
    <Headline v-if="title" class="mt-4 text-center" :title="title" />
    <Headline
      v-if="offers.length === 0"
      :h="3"
      class="my-2 text-center lg:mb-2 lg:mt-4"
      title="Zur Zeit keine Angebote verfügbar."
    />
    <div v-if="offers.length > 0" class="mt-2 flex flex-col lg:mt-0">
      <div
        v-for="data in offers"
        :key="data.display_id.value"
        class="my-2 lg:my-4"
      >
        <CruiseListOffer
          :cta-orange="ctaOrange"
          :offer="data"
          :show-bookmark="showBookmark"
          @refresh-offers="emit('refresh-offers')"
        />
      </div>
    </div>

    <div v-if="button" class="m-4 flex justify-center">
      <G7Button
        class="p-2"
        color="orange"
        :to="button.context"
        :target="button.target"
        :label="button.label"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CruiseListOfferType } from "~~/types/cruise";
import type { ActionButton } from "~~/types/form";

interface Props {
  ctaOrange?: boolean;
  title?: string;
  showBookmark?: boolean;
  offers: Array<CruiseListOfferType>;
  button?: ActionButton | null;
}

withDefaults(defineProps<Props>(), {
  ctaOrange: false,
  title: "",
  showBookmark: false,
  offers: () => [] as CruiseListOfferType[],
  button: null,
});

const emit = defineEmits(["refresh-offers"]);
</script>
