<template>
  <div :class="classes" class="flex h-fit w-full bg-g7-blue">
    <component :is="component" :to="context" :target="target">
      <Headline
        class="my-1 ml-2 font-black lg:my-2 lg:ml-4"
        color="white"
        :title="title"
      />
    </component>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { Target } from "~/types/form";

const props = defineProps<{
  context?: string;
  target?: Target;
  title: string;
}>();

const slots = useSlots();
const classes = computed(() => {
  if (slots.default) {
    return "justify-between";
  }
});

const component = computed(() => {
  if (props.context) {
    return resolveComponent("NuxtLink");
  }
  return "div";
});
</script>
